import Request from './request';

//开始数据
export async function login(params) {
  return Request({
    url: 'admin/base/wxLoginAuth',
    method: 'post',
    data: params
  })
}
