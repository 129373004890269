import axios from 'axios'
import config from './../config'
import { Toast } from 'vant'
import store from './../store'
import qs from 'qs'
import Store from 'store'
import router from './../router'

const authKey = Store.get('authKey') || false
const sessionId = Store.get('sessionId') || false
const request = axios.create({
  baseURL: config.API_URL,
  timeout: 50000,
  headers: {
    'authKey':authKey,
    'sessionId':sessionId,
  },
  params: {},
  data:{},
})

const file_request = axios.create({
  baseURL: config.API_URL,
  timeout: 50000,
  headers: {
    'Content-Type':'application/x-www-form-urlencoded'
  },
})

const map_request = axios.create({
  baseURL: '',
  timeout: 50000,
  headers: {
  },
})

function fetch(options, params, fnc) {
  let {
    method = 'get',
    data,
    url,
  } = options

  data = data || {}
  if(authKey) {
    if(!params) {
      // data['authKey'] = authKey
      // data['sessionId'] = sessionId
    } else if(params.type === 'file_upload') {
      data.append('authKey', authKey)
      data.append('sessionId', sessionId)
    }
  }

  if(method.toLowerCase() === 'get') {
    data= qs.stringify(data, {arrayFormat: 'repeat'})
    url = url + '?' + data
  }

  switch (method.toLowerCase()) {
    case 'get':
      // return fnc.get(url, { params: data })
      return fnc.get(url)
    case 'delete':
      return fnc.delete(url, { data })
    case 'head':
      return fnc.head(url, data)
    case 'post':
      return fnc.post(url, data)
    case 'put':
      return fnc.put(url, JSON.stringify(data))
    case 'patch':
      return fnc.patch(url, data)
    default:
      return fnc(options)
  }
}

function checkStatus (res) {
  // console.log("执行第一步",res)
  if (res.status >= 200 && res.status < 300) {
    return res
  }
  const error = new Error(res.statusText)
}

function handelData (res) {
  const data = res.data
  // console.log("执行第二步",res)
  if(!data.code) {
    //没有返回则默认进入登录页/
    Toast({
      message: data.error,
      onClose: () => {
        window.location.href="/"
      }
    })
  }else if(data.code == 101){
    //发现authKey失效时重新登录/
    Toast({
      message: data.error,
      onClose: () => {
        Store.remove('userInfo')
        Store.remove('authKey')
        Store.remove('sessionId')
        Store.remove('authList')
        window.location.href="/"
      }
    })
  }
  return data;
}

function handleError (error) {
  console.log("执行第三步",error)
  return { success: false }
}

export default (options, params=false) => {
  if(params && params.type === 'file_upload') {
    var result = fetch(options, params, file_request)
      .then(checkStatus)
      .then(handelData)
      .catch(handleError)
    Toast.clear();
    return result;
  } else if(params && params.type === 'map_request') {
    var result = fetch(options, params, map_request)
      .then(checkStatus)
      .then(handelData)
      .catch(handleError)
    Toast.clear();
    return result;
  } else {
    var result = fetch(options, params, request)
      .then(checkStatus)
      .then(handelData)
      .catch(handleError)
    return result;
  }
}
